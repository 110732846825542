<template>
  <div class="content">
    <van-list
      v-model="loading"
      :finished="finished"
      :error.sync="error"
      error-text="请求失败，点击重新加载"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div v-for="(item, index) in list" :key="index" style="overflow-y: scroll">
        <van-row class="cardItem">
          <van-col span="7">
            <van-image
              class="img"
              width="100"
              height="100"
              :src="yanganurl"
              @click="toDeviceData(index)"
            />
          </van-col>
          <van-col span="17">
            <p>{{deviceName}}</p>
            <p v-if="type==1 &&item.latestDataList.length>0">{{item.latestDataList[0].des}}:{{item.latestDataList[0].value}}</p>
            <p v-else-if="type==2 &&item.latestDataList.length>0">{{item.latestDataList[0].des}}:{{item.latestDataList[0].value}}</p>
            <p v-else-if="type==3 &&item.latestDataList.length>0">{{item.latestDataList[0].des}}:{{getValue(item.latestDataList[0].value,item.latestDataList[0].type,item.latestDataList[0].unit)}}</p>
            <p v-else>暂无最新数据</p>
            <p v-if="item.online" style="color:#008000">在线</p>
            <p v-else style="color:#F20">离线</p>
          </van-col>
        </van-row>
      </div>
      
    </van-list>
    
  </div>
</template>

<script>
import { List } from "vant";
export default {
  data() {
    return {
        type:-1,
        typeid:-1,
      deviceName:"",
      list: [],
      loading: false,
      finished: false,
      error: false,
      page: 1,
      curData:'',
       yanganurl:
        "http://www.rkonfly.cn/upload/file/content/2022/05/62831321666bd.jpg",
    };
  },
  mounted(){
    this.type = this.$route.query.type
    if(this.type == 1){
        this.deviceName = "燃气设备"
        this.typeid = 2
        this.yanganurl = require('../assets/ranqi.jpg')
    }else if(this.type == 2){
        this.deviceName = "烟感设备"
         this.typeid = 3
         this.yanganurl = require('../assets/yangan.jpg')
    }else {
        this.deviceName = "空开设备"
         this.typeid = 1
         this.yanganurl = require('../assets/kongkai.jpg')
    }
  },
  methods: {
     getValue(value,type,unit){  
          if(value == 0 && type == 205){
              return '关'
          }
          if(value == 1 && type == 205){
              return '开'
          }
          return value+unit
    },
    toDeviceData(index){
      this.$router.push({path:'/deviceData',query:{index:index,type:this.$route.query.type}})
    },
    onLoad() {
      this.http
        .get("/mp/sg/devices", {
          params: {
            companyId: localStorage.getItem("companyId"),
            deviceClassifyType: this.typeid,
            page: this.page,
            size: 10,
          },
        })
        .then(({ data }) => {
          if (data.code == 200) {
            this.loading = false;
            this.list = this.list.concat(data.data.list);
            if (this.page == data.data.lastPage || !data.data.hasNextPage) {
              this.finished = true;
            }
            this.page++;
          } else {
            this.loading = false;
            this.error = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.error = true;
        });
    },
  },
};
</script>

<style>
.content {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
    
}
 .cardItem {
      background-color: #ffffff;
      width: 100%;
      height: 200px;
     
    }
     .img {
        margin-top: 16px;
        margin-left: 10px;
      }
</style>